import { Script } from "gatsby"
import { useSiteMetadata } from "./useSiteMetadata"
import icon from "./images/icon.svg"

export const Head = ({ title, description, location, children }) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata()
    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${location?.pathname || ``}`,
        twitterUsername,
    }
    const pathParts = location?.pathname.split("/") || []
    const pathChunks = pathParts.length > 1
        ? pathParts
            .slice(1)
            .filter(Boolean)
            .map(([first, ...rest]) => [first.toUpperCase(), ...rest].join(""))
            .join(" - ")
        : null
    const titleWithPathChunks = pathChunks ? `${pathChunks} - ${seo.title} - ${seo.description}` : `${seo.title} - ${seo.description}`;
    return (
        <>
            <title id="title">{titleWithPathChunks}</title>
            <meta name="description" content={seo.description} />
            <meta name="image" content={icon} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={seo.title} />
            <meta property="og:title" content={pathChunks} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            <meta property="og:image:height" content={512} />
            <meta property="og:image:width" content={512} />
            <meta property="og:url" content={seo.url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:url" content={seo.url} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
            <Script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": siteUrl,
                    "logo": seo.image
                })}
            </Script>
            {children}
        </>
    )
}
